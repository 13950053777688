import { BaseService } from './BaseService';

export interface SaveSettingsResponse {
    isSuccess: boolean;
}

export interface FixedResponse {
    input: string;
    output: string;
}

export interface Preset {
    label: string;
    content: string;
}

export interface Settings {
    systemDescription: string;
    initialBotMessage: string;
    fixedResponses: FixedResponse[];
    presets: Preset[];
    systemIntent: string;
    systemIntentEnabled: boolean;
    documentRetrievalMaxCount: number;
    model: string;
    responseTopP: number;
    responseTemperature: number;
}

export class SettingsService extends BaseService {
    save = async (settings: Settings, accessToken: string): Promise<SaveSettingsResponse> => {
        return await this.getResponseAsync<SaveSettingsResponse>(
            {
                commandPath: `settings`,
                method: 'POST',
                body: settings,
            },
            accessToken,
        );
    };

    load = async (accessToken: string): Promise<Settings> => {
        return await this.getResponseAsync<Settings>(
            {
                commandPath: `settings`,
                method: 'GET',
            },
            accessToken,
        );
    };

    getPresets = async (accessToken: string): Promise<Preset[]> => {
        return await this.getResponseAsync<Preset[]>(
            {
                commandPath: `settings/presets`,
                method: 'GET',
            },
            accessToken,
        );
    };
}
