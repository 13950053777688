import * as React from 'react';
import { useArchive } from '../../libs/hooks';
import { IChatMessage } from '../../libs/models/ChatMessage';
import {
    Button,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    InlineDrawer,
    makeStyles,
    tokens,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import { ChatHistory } from '../chat/chat-history/ChatHistory';
import { SharedStyles } from '../../styles';
import { Loading } from '../views';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        border: 'inset 2px solid red',
    },
    scroll: {
        ...SharedStyles.scroll,
    },
    details: {
        width: '100%',
        minWidth: '500px',
        paddingLeft: tokens.spacingHorizontalM,
    },
});

export interface DetailsPanelProps {
    onClose: () => void;
    chatId?: string;
    height: number;
}

export const DetailsPanel: React.FC<DetailsPanelProps> = ({ onClose, chatId, height }) => {
    const classes = useClasses();
    const archive = useArchive();

    const [messages, setMessages] = React.useState<IChatMessage[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const loadMessages = () => {
        if (!chatId) {
            setMessages([]);
            return;
        }

        setIsLoading(true);

        void archive
            .loadMessages(chatId)
            .then((messages) => {
                if (!messages) {
                    setMessages([]);
                    return;
                }

                // sort by reverse timestamp
                messages.sort((a, b) => a.timestamp - b.timestamp);

                setMessages(messages);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        loadMessages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatId]);

    return (
        <InlineDrawer className={classes.details} open={!!chatId} position="end" style={{ height: `${height}px` }}>
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Schließen"
                            icon={<Dismiss24Regular />}
                            onClick={onClose}
                        />
                    }
                >
                    Details
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody className={classes.scroll}>
                {isLoading && <Loading text="Lade Nachrichten" />}
                {!isLoading && <ChatHistory enableArchiveMode={true} messages={messages} />}
            </DrawerBody>
        </InlineDrawer>
    );
};
