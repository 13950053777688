import React, { useState } from 'react';
import { Preset } from '../../libs/services/SettingsService';
import { Button, Input, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { AddRegular, DeleteRegular, ArrowDown16Regular, ArrowUp16Regular } from '@fluentui/react-icons';

export interface EditablePresetsListProps {
    initialItems: Preset[];
    onItemsChange: (items: Preset[]) => void;
}

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    input: {
        flexGrow: 1,
        maxWidth: 'auto',
    },
    spacerH: {
        width: '10px',
    },
});

export const EditablePresetsList: React.FC<EditablePresetsListProps> = ({ initialItems, onItemsChange }) => {
    const classes = useClasses();

    const [items, setItems] = useState(initialItems);

    React.useEffect(() => {
        onItemsChange(items);
    }, [items, onItemsChange]);

    const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newItems = [...items];
        newItems[index][event.target.name as keyof Preset] = event.target.value;
        setItems(newItems);
    };

    const handleAddItem = () => {
        setItems([...items, { label: '', content: '' }]);
    };

    const handleRemoveItem = (index: number) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    const handleMoveItemUp = (index: number) => {
        if (index === 0) return;
        const newItems = [...items];
        [newItems[index - 1], newItems[index]] = [newItems[index], newItems[index - 1]];
        setItems(newItems);
    };

    const handleMoveItemDown = (index: number) => {
        if (index === items.length - 1) return;
        const newItems = [...items];
        [newItems[index + 1], newItems[index]] = [newItems[index], newItems[index + 1]];
        setItems(newItems);
    };

    return (
        <div className={classes.root}>
            {items.map((item, index) => (
                <div key={`preset-${index}`} className={classes.row}>
                    <Input
                        className={classes.input}
                        name="label"
                        value={item.label}
                        onChange={(event) => {
                            handleInputChange(index, event);
                        }}
                        placeholder="Label"
                        autoComplete="off"
                    />
                    <Input
                        className={classes.input}
                        name="content"
                        value={item.content}
                        onChange={(event) => {
                            handleInputChange(index, event);
                        }}
                        placeholder="Nachricht"
                        autoComplete="off"
                    />
                    <Button
                        size="small"
                        icon={<ArrowUp16Regular />}
                        onClick={() => {
                            handleMoveItemUp(index);
                        }}
                        appearance="transparent"
                        disabled={index === 0}
                    />
                    <Button
                        size="small"
                        icon={<ArrowDown16Regular />}
                        onClick={() => {
                            handleMoveItemDown(index);
                        }}
                        appearance="transparent"
                        disabled={index === items.length - 1}
                    />
                    <div className={classes.spacerH} />
                    <Button
                        icon={<DeleteRegular />}
                        onClick={() => {
                            handleRemoveItem(index);
                        }}
                    />
                </div>
            ))}
            <Button icon={<AddRegular />} appearance="subtle" onClick={handleAddItem}>
                Weiteren Hotbutton
            </Button>
        </div>
    );
};
