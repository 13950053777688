import {
    Button,
    Divider,
    Field,
    Input,
    makeStyles,
    shorthands,
    Spinner,
    Switch,
    Text,
    Textarea,
    tokens,
} from '@fluentui/react-components';
import React, { useState } from 'react';
import { SharedStyles } from '../../styles';
import { useSettings } from '../../libs/hooks/useSettings';
import { FixedResponse, Preset, Settings } from '../../libs/services/SettingsService';
import { EditableFixedResponsesList } from './EditableFixedResponsesList';
import { Loading } from '../views';
import { EditablePresetsList } from './EditablePresetsList';
import { Export, Import } from './ImportExport';
import { SaveRegular } from '@fluentui/react-icons';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: tokens.fontWeightSemibold,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('8px'),
    },
    scroll: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('32px', '32px'),
        ...shorthands.gap('16px'),
        ...shorthands.margin(tokens.spacingVerticalM),
        ...SharedStyles.scroll,
    },
    info: {
        ...shorthands.margin('auto'),
    },
});

interface ValidationErrors {
    systemDescription?: string;
    initialBotMessage?: string;
    fixedResponses?: string;
    presets?: string;
    systemIntent?: string;
    documentRetrievalMaxCount?: string;
}

export const SettingsWindow: React.FC = () => {
    const classes = useClasses();
    const settings = useSettings();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(true);
    const [data, setData] = useState<Settings>({
        systemDescription: '',
        initialBotMessage: '',
        fixedResponses: [],
        presets: [],
        systemIntent: '',
        systemIntentEnabled: false,
        documentRetrievalMaxCount: 1,
        model: '',
        responseTopP: 0,
        responseTemperature: 0,
    });

    function loadData() {
        setIsLoading(true);
        settings
            .load()
            .then((loadedData) => {
                setData(loadedData);
            })
            .catch((_) => {})
            .finally(() => {
                setIsLoading(false);
            });
    }

    function saveData() {
        setIsSaving(true);
        settings
            .save({
                systemDescription: data.systemDescription.trim(),
                initialBotMessage: data.initialBotMessage.trim(),
                fixedResponses: data.fixedResponses,
                presets: data.presets,
                systemIntent: data.systemIntent.trim(),
                systemIntentEnabled: data.systemIntentEnabled,
                documentRetrievalMaxCount: data.documentRetrievalMaxCount,
                model: data.model,
                responseTopP: data.responseTopP,
                responseTemperature: data.responseTemperature,
            })
            .catch((_) => {})
            .finally(() => {
                setIsSaving(false);
            });
    }

    const handleFixedResponsesChange = (fixedResponses: FixedResponse[]) => {
        if (fixedResponses == data.fixedResponses) {
            return;
        }

        setData({
            ...data,
            fixedResponses,
        });
    };

    const handlePresetsChange = (presets: Preset[]) => {
        if (presets == data.presets) {
            return;
        }

        setData({
            ...data,
            presets,
        });
    };

    // Handle validation
    const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
    React.useEffect(() => {
        let canSave = true;
        const validationErrors: ValidationErrors = {};

        if (data.systemDescription.trim().length === 0) {
            validationErrors.systemDescription = 'Wert benötigt';
            canSave = false;
        }
        if (data.initialBotMessage.trim().length === 0) {
            validationErrors.initialBotMessage = 'Wert benötigt';
            canSave = false;
        }
        if (data.systemIntent.trim().length === 0) {
            validationErrors.systemIntent = 'Wert benötigt';
            canSave = false;
        }
        if (data.documentRetrievalMaxCount < 1 || data.documentRetrievalMaxCount > 10) {
            validationErrors.documentRetrievalMaxCount = 'Wert benötigt (Zahl von 1 bis 10)';
            canSave = false;
        }
        if (
            data.fixedResponses.reduce(
                (c, v) => c || v.input.trim().length === 0 || v.output.trim().length === 0,
                false,
            )
        ) {
            validationErrors.fixedResponses = 'Wert benötigt';
            canSave = false;
        }
        if (data.presets.reduce((c, v) => c || v.label.trim().length === 0 || v.content.trim().length === 0, false)) {
            validationErrors.presets = 'Wert benötigt';
            canSave = false;
        }

        setValidationErrors(validationErrors);
        setCanSave(canSave);
    }, [data]);

    // Load initial data
    React.useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>Einstellungen</div>
                <div className={classes.controls}>
                    {isSaving && <Spinner size="extra-tiny" label="Speichere..."></Spinner>}
                    <Import
                        onImport={(newSettings) => {
                            setData({ ...data, ...newSettings });
                        }}
                    />
                    <Export settings={data} />
                    <Button
                        appearance="primary"
                        icon={<SaveRegular />}
                        onClick={(_) => {
                            saveData();
                        }}
                        disabled={!canSave}
                    >
                        Speichern
                    </Button>
                </div>
            </div>
            {isLoading && <Loading text="Lade Daten" />}
            {!isLoading && (
                <div className={classes.scroll}>
                    <Field orientation="horizontal" label="Model">
                        <Text>
                            {data.model}; TopP: {data.responseTopP}; Temperature: {data.responseTemperature}
                        </Text>
                    </Field>

                    <Divider />

                    <Field
                        orientation="horizontal"
                        required
                        label="Prompt"
                        validationState={validationErrors.systemDescription ? 'error' : 'none'}
                        validationMessage={validationErrors.systemDescription}
                    >
                        <Textarea
                            value={data.systemDescription}
                            onChange={(_, content) => {
                                setData({
                                    ...data,
                                    systemDescription: content.value,
                                });
                            }}
                            resize="vertical"
                            textarea={{
                                style: {
                                    minHeight: '300px',
                                    maxHeight: 'unset',
                                },
                            }}
                        />
                    </Field>

                    <Field
                        orientation="horizontal"
                        required
                        label="Initiale Nachricht"
                        validationState={validationErrors.initialBotMessage ? 'error' : 'none'}
                        validationMessage={validationErrors.initialBotMessage}
                    >
                        <Textarea
                            value={data.initialBotMessage}
                            onChange={(_, content) => {
                                setData({
                                    ...data,
                                    initialBotMessage: content.value,
                                });
                            }}
                            resize="vertical"
                        />
                    </Field>

                    <Divider />

                    <Field
                        orientation="horizontal"
                        required
                        label="Intenterkennung"
                        validationState={validationErrors.systemIntent ? 'error' : 'none'}
                        validationMessage={validationErrors.systemIntent}
                    >
                        <Switch
                            checked={data.systemIntentEnabled}
                            onChange={(_, content) => {
                                setData({
                                    ...data,
                                    systemIntentEnabled: content.checked,
                                });
                            }}
                            label="Intenterkennung aktivieren"
                            required={false}
                        />
                        <Textarea
                            value={data.systemIntent}
                            onChange={(_, content) => {
                                setData({
                                    ...data,
                                    systemIntent: content.value,
                                });
                            }}
                            resize="vertical"
                            textarea={{
                                style: {
                                    minHeight: '150px',
                                    maxHeight: 'unset',
                                },
                            }}
                        />
                    </Field>

                    <Field
                        orientation="horizontal"
                        required
                        label="Maximale Ergebnisse pro Index-Suche (RAG)"
                        validationState={validationErrors.documentRetrievalMaxCount ? 'error' : 'none'}
                        validationMessage={validationErrors.documentRetrievalMaxCount}
                    >
                        <Input
                            type="number"
                            min={1}
                            max={10}
                            value={data.documentRetrievalMaxCount.toString()}
                            onChange={(_, content) => {
                                setData({
                                    ...data,
                                    documentRetrievalMaxCount: Number(content.value),
                                });
                            }}
                        />
                    </Field>

                    <Divider />

                    <Field orientation="horizontal" label="Feste Antworten">
                        <EditableFixedResponsesList
                            initialItems={data.fixedResponses}
                            onItemsChange={handleFixedResponsesChange}
                        />
                    </Field>

                    <Divider />

                    <Field orientation="horizontal" label="Hotbuttons">
                        <EditablePresetsList initialItems={data.presets} onItemsChange={handlePresetsChange} />
                    </Field>
                </div>
            )}
        </div>
    );
};
