import {
    Button,
    createTableColumn,
    Input,
    makeStyles,
    shorthands,
    TableCellLayout,
    TableColumnDefinition,
    tokens,
    Tooltip,
} from '@fluentui/react-components';
import {
    DataGridBody,
    DataGrid,
    DataGridRow,
    DataGridHeader,
    DataGridCell,
    DataGridHeaderCell,
    DataGridProps,
    RowRenderer,
} from '@fluentui-contrib/react-data-grid-react-window';
import { ArrowDownloadRegular, ArrowSyncRegular, DismissRegular, SearchRegular } from '@fluentui/react-icons';
import * as React from 'react';
import { useArchive } from '../../libs/hooks';
import { SharedStyles } from '../../styles';
import { Alerts } from '../shared/Alerts';
import { IChatArchiveSession } from '../../libs/models/ChatSession';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DetailsPanel } from './DetailsPanel';
import { Loading } from '../views';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground1,
        border: 'inset 2px solid red',
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground1,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        fontWeight: tokens.fontWeightSemibold,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('10px'),
    },
    alerts: {
        ...shorthands.margin('4px'),
    },
    scrollList: {
        ...SharedStyles.scroll,
    },
    dataGrid: {
        width: '100%',
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: tokens.colorNeutralBackground1,
        flexGrow: 1,
    },
    loading: {
        flexGrow: 1,
    },
});

export const ArchiveWindow: React.FC = () => {
    const classes = useClasses();
    const archive = useArchive();

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 14);
    const maxDate = new Date();
    maxDate.setHours(23, 59, 59, 999);

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const [fromDate, setFromDate] = React.useState(sevenDaysAgo);
    const [toDate, setToDate] = React.useState(maxDate);
    const [search, setSearch] = React.useState<string>('');

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [items, setItems] = React.useState<IChatArchiveSession[]>([]);
    const [filteredItems, setFilteredItems] = React.useState<IChatArchiveSession[]>([]);

    const updateList = () => {
        setIsLoading(true);

        const finalFromDate = new Date(fromDate);
        finalFromDate.setHours(0, 0, 0, 0);

        const finalToDate = new Date(toDate);
        finalToDate.setDate(finalToDate.getDate() + 1);

        void archive
            .loadChats(finalFromDate, finalToDate)
            .then((chats) => {
                if (!chats) {
                    setItems([]);
                    return;
                }

                setItems(chats);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        updateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        updateList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, toDate]);

    React.useEffect(() => {
        setSelectedChatId(undefined);

        if (search.trim() === '') {
            setFilteredItems(items);
            return;
        }

        const filteredItems = items.filter((item) => {
            return item.title.toLowerCase().includes(search.toLowerCase().trim());
        });
        setFilteredItems(filteredItems);
    }, [search, items]);

    const columns: Array<TableColumnDefinition<IChatArchiveSession>> = [
        createTableColumn<IChatArchiveSession>({
            columnId: 'title',
            renderHeaderCell: () => {
                return 'Titel';
            },
            renderCell: (item) => (
                <TableCellLayout truncate style={{ maxWidth: '30vw' }}>
                    {item.title}
                </TableCellLayout>
            ),
            compare: (a, b) => {
                return a.title.localeCompare(b.title);
            },
        }),
        createTableColumn<IChatArchiveSession>({
            columnId: 'createdOn',
            renderHeaderCell: () => {
                return 'Erstellt am';
            },
            renderCell: (item) => {
                return item.id.startsWith('in-progress') ? 'N/A' : formatDateTimeToLocal(Date.parse(item.createdOn));
            },
            compare: (a, b) => {
                return a.createdOn > b.createdOn ? 1 : -1;
            },
        }),
    ];

    const [sortState, setSortState] = React.useState<Parameters<NonNullable<DataGridProps['onSortChange']>>[1]>({
        sortColumn: 'createdOn',
        sortDirection: 'descending',
    });
    const onSortChange: DataGridProps['onSortChange'] = (_, nextSortState) => {
        setSortState(nextSortState);
    };

    const columnSizingOptions = {
        title: {
            defaultWidth: 360,
            minWidth: 240,
            idealWidth: 360,
        },
        createdOn: {
            minWidth: 240,
            defaultWidth: 240,
        },
    };

    const [selectedChatId, setSelectedChatId] = React.useState<string>();

    const [isExporting, setIsExporting] = React.useState<boolean>(false);
    const downloadXLSX = () => {
        setIsExporting(true);
        archive
            .downloadListExport(fromDate, toDate)
            .then((blob) => {
                if (!blob) {
                    return;
                }

                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `ArchiveExport.${location.host}.xlsx`;
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch(console.log)
            .finally(() => {
                setIsExporting(false);
            });
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div className={classes.title}>
                    Archiv{' '}
                    {items.length &&
                        `(${filteredItems.length != items.length ? `${filteredItems.length}/` : ''}${items.length})`}
                </div>
                <div className={classes.controls}>
                    <Input
                        type="text"
                        value={search}
                        placeholder="Titel durchsuchen"
                        onChange={(_, data) => {
                            setSearch(data.value);
                        }}
                        style={{ width: '250px' }}
                        contentBefore={<SearchRegular />}
                        contentAfter={
                            <span>
                                {search !== '' && (
                                    <Button
                                        appearance={'transparent'}
                                        size="small"
                                        icon={<DismissRegular />}
                                        onClick={() => {
                                            setSearch('');
                                        }}
                                    />
                                )}
                            </span>
                        }
                    />
                    <Input
                        type="date"
                        contentBefore={<span>von</span>}
                        min={formatDateString(minDate)}
                        max={formatDateString(getMinimumDate(maxDate, toDate))}
                        value={formatDateString(fromDate)}
                        onChange={(_, data) => {
                            if (data.value === '') {
                                setFromDate(getMinimumDate(sevenDaysAgo, toDate));
                                return;
                            }
                            const newDate = new Date(`${data.value}`);
                            setFromDate(newDate);
                        }}
                    />
                    <Input
                        type="date"
                        contentBefore={<span>bis</span>}
                        min={formatDateString(getMaximumDate(minDate, fromDate))}
                        max={formatDateString(maxDate)}
                        value={formatDateString(toDate)}
                        onChange={(_, data) => {
                            if (data.value === '') {
                                setToDate(getMaximumDate(today, toDate));
                                return;
                            }
                            const newDate = new Date(`${data.value}`);
                            newDate.setHours(23, 59, 59, 999);
                            setToDate(newDate);
                        }}
                    />

                    <Tooltip content="Liste aktualisieren" relationship="label" withArrow>
                        <Button
                            icon={<ArrowSyncRegular />}
                            onClick={() => {
                                updateList();
                            }}
                            disabled={isLoading}
                        />
                    </Tooltip>

                    <Tooltip content="Als Excel Datei herunterladen" relationship="label" withArrow>
                        <Button
                            icon={<ArrowDownloadRegular />}
                            onClick={() => {
                                downloadXLSX();
                            }}
                            disabled={isExporting}
                        >
                            .xlsx
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <div className={classes.alerts}>
                <Alerts />
            </div>
            <div className={classes.content}>
                {isLoading && (
                    <div className={classes.loading}>
                        <Loading text="Lade Chats" />
                    </div>
                )}
                {isExporting && (
                    <div className={classes.loading}>
                        <Loading text="Exportiere... Bitte warten." />
                    </div>
                )}
                {!isLoading && !isExporting && (
                    <>
                        <DataGrid
                            items={filteredItems}
                            columns={columns}
                            sortable
                            sortState={sortState}
                            onSortChange={onSortChange}
                            selectionMode="single"
                            selectionAppearance="neutral"
                            onSelectionChange={(_, data) => {
                                setSelectedChatId(([...data.selectedItems] as string[])[0]);
                            }}
                            selectedItems={selectedChatId ? [selectedChatId] : []}
                            getRowId={(item: IChatArchiveSession) => item.id}
                            resizableColumns
                            resizableColumnsOptions={{ autoFitColumns: false }}
                            columnSizingOptions={columnSizingOptions}
                            className={classes.dataGrid}
                        >
                            <DataGridHeader>
                                <DataGridRow>
                                    {({ renderHeaderCell }) => (
                                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                    )}
                                </DataGridRow>
                            </DataGridHeader>
                            <AutoSizer disableWidth={true}>
                                {({ height }) => (
                                    <DataGridBody<IChatArchiveSession>
                                        itemSize={40}
                                        height={height - 33}
                                        listProps={{ className: classes.scrollList }}
                                    >
                                        {renderRow}
                                    </DataGridBody>
                                )}
                            </AutoSizer>
                        </DataGrid>

                        <AutoSizer disableWidth={true}>
                            {({ height }) => (
                                <DetailsPanel
                                    onClose={() => {
                                        setSelectedChatId(undefined);
                                    }}
                                    chatId={selectedChatId}
                                    height={height}
                                />
                            )}
                        </AutoSizer>
                    </>
                )}
            </div>
        </div>
    );
};

const renderRow: RowRenderer<IChatArchiveSession> = ({ item, rowId }, style) => (
    <DataGridRow<IChatArchiveSession> key={rowId} style={style}>
        {({ renderCell }) => <DataGridCell focusMode="group">{renderCell(item)}</DataGridCell>}
    </DataGridRow>
);

function formatDateTimeToLocal(timestamp: number): string {
    const date = new Date(timestamp);

    return date.toLocaleString([], {});
}

function formatDateString(date: Date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

function getMinimumDate(a: Date, b: Date): Date {
    return a < b ? a : b;
}

function getMaximumDate(a: Date, b: Date): Date {
    return a > b ? a : b;
}
